<!--
 * @Description: 会员订单
 * @Author: huacong
 * @Date: 2021-06-08 10:20:27
 * @LastEditTime: 2021-07-23 17:01:56
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <section>
      <el-row :gutter="16" class="margin-bottom-16">
        <el-col :span="6">
          <div class="vipInfoBox first">
            <div class="vipBoxLeft">
              <div class="vipFunction">
                {{
                  vipInfo.u_grade_text == "" ? "普通用户" : vipInfo.u_grade_text
                }}
              </div>
            </div>
            <div class="vipBoxRight">
              <p class="vrtop">会员有效期</p>
              <p class="vrmiddle">
                <span class="vipNum" v-if="vipEndTime">
                  <span style="font-size: 16px;">至</span> {{ vipEndTime }}
                </span>
                <span class="vipGuoqi" v-if="vipEndTime == ''"> 已过期 </span>
              </p>
              <p class="text-right" style="padding-right:15px">
                <el-button
                  type="text"
                  class="xufeiBtn"
                  v-prevent-repeat-click
                  @click="gorenew"
                  >立即续费</el-button
                >
              </p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="vipInfoBox second">
            <div class="vipBoxLeft">
              <div class="vipFunction">发布条数</div>
            </div>
            <div class="vipBoxRight">
              <p class="vrtop">本月剩余</p>
              <p class="vrmiddle">
                <span class="vipNum"> {{ vipInfo.u_surplus_push }} </span>条
              </p>
              <p class="text-right" style="padding-right:15px">
                <el-button
                  type="text"
                  class="xufeiBtn"
                  v-prevent-repeat-click
                  @click="gorenew"
                  >立即续费</el-button
                >
              </p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="vipInfoBox three">
            <div class="vipBoxLeft">
              <div class="vipFunction">刷新次数</div>
            </div>
            <div class="vipBoxRight">
              <p class="vrtop">本月剩余</p>
              <p class="vrmiddle">
                <span class="vipNum"> {{ vipInfo.u_surplus_refresh }} </span>条
              </p>
              <p class="text-right" style="padding-right:15px">
                <el-button
                  type="text"
                  class="xufeiBtn"
                  v-prevent-repeat-click
                  @click="gorenew"
                  >立即续费</el-button
                >
              </p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="vipInfoBox four">
            <div class="vipBoxLeft">
              <div class="vipFunction">下载次数</div>
            </div>
            <div class="vipBoxRight">
              <p class="vrtop">本月剩余</p>
              <p class="vrmiddle">
                <span class="vipNum"> {{ vipInfo.u_surplus_down }} </span>条
              </p>
              <p class="text-right" style="padding-right:15px">
                <el-button
                  type="text"
                  class="xufeiBtn"
                  v-prevent-repeat-click
                  @click="gorenew"
                  >立即续费</el-button
                >
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="bgfff">
        <el-col style="padding: 0px 16px">
          <el-tabs v-model="tabName" @tab-click="changetab">
            <el-tab-pane label="充值成功订单" name="first">
              <div style="padding: 0px 16px">
                <el-table
                  :data="tableInfo1"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="{ background: '#EBEEF5' }"
                >
                  <el-table-column prop="transaction_time" label="购买时间">
                  </el-table-column>
                  <el-table-column prop="trade_no" label="订单编号">
                  </el-table-column>
                  <!-- <el-table-column prop="status_text" label="支付方式">
                  </el-table-column> -->
                  <el-table-column prop="account" label="金额（元）">
                  </el-table-column>
                  <el-table-column prop="pay_type" label="订单类型">
                  </el-table-column>
                  <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                      <div>
                        <span v-if="scope.row.status == 1">
                          未支付
                        </span>
                        <span v-if="scope.row.status == 2">
                          支付成功
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <div class="btnBox1">
                        <el-button
                          type="text"
                          v-prevent-repeat-click
                          @click="gorenew(scope.row)"
                          style="color: #2573F1"
                        >
                          去续费
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                  <div slot="empty" class="empty">
                    <div>
                      <img :src="noDate" />
                    </div>
                    <span>暂无数据</span>
                  </div>
                </el-table>
                <div class="pageBox">
                  <el-pagination
                    background
                    @size-change="sizeChange1"
                    @current-change="currentChange1"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="未完成订单" name="second">
              <div style="padding: 0px 16px">
                <el-table
                  :data="tableInfo2"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="{ background: '#EBEEF5' }"
                >
                  <el-table-column prop="transaction_time" label="购买时间">
                  </el-table-column>
                  <el-table-column prop="trade_no" label="订单编号">
                  </el-table-column>
                  <!-- <el-table-column prop="status_text" label="支付方式">
                  </el-table-column> -->
                  <el-table-column prop="account" label="金额（元）">
                  </el-table-column>
                  <el-table-column prop="pay_type" label="订单类型">
                  </el-table-column>
                  <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                      <div>
                        <span v-if="scope.row.status == 1">
                          未支付
                        </span>
                        <span v-if="scope.row.status == 2">
                          支付成功
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <div class="btnBox1">
                        <el-button
                          type="text"
                          v-prevent-repeat-click
                          @click="goxufei(scope.row)"
                          style="color: #2573F1"
                        >
                          去续费
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                  <div slot="empty" class="empty">
                    <div>
                      <img :src="noDate" />
                    </div>
                    <span>暂无数据</span>
                  </div>
                </el-table>
                <div class="pageBox">
                  <el-pagination
                    background
                    @size-change="sizeChange2"
                    @current-change="currentChange2"
                    :current-page="currentPage2"
                    :page-sizes="[10, 20, 30]"
                    :page-size="pagesize2"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal2"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      noDate: require("@/assets/img/noDate.png"),
      tabName: "first",
      tableInfo1: [],
      tableInfo2: [],
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      searchInfo: {},
      currentPage2: 1,
      pagesize2: 10,
      tableTotal2: 0,
      searchInfo2: {},
      vipInfo: {},
      vipEndTime: "",
    };
  },
  computed: {
    userInfo() {
      let k = localStorage.getItem("userInfo");
      console.log(k);
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    ...mapMutations([
      "setorderId",
      "setorderTime",
      "setorderType",
      "setorderFee",
      "setcodeImg",
    ]),
    // 切换tab
    changetab() {},
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getTableData1(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getTableData1(e, this.pagesize);
    },
    getTableData1(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 10;
      } else {
        this.searchInfo.page = this.currentPage;
        this.searchInfo.limit = this.pagesize;
      }
      this.searchInfo.type = 1;
      this.$http
        .post("index/recharge/myrechargerecord", this.searchInfo)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.tableInfo1 = res.data.resultData.list;
            this.tableTotal = parseInt(res.data.resultData.count);
          }
        });
    },
    sizeChange2(e) {
      this.pagesize2 = e;
      this.currentPage2 = 1;
      this.getTableData2(this.currentPage2, this.pagesize2);
    },
    currentChange2(e) {
      this.currentPage2 = e;
      this.getTableData2(e, this.pagesize2);
    },
    getTableData2(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo2.page = 1;
        this.searchInfo2.limit = 10;
      } else {
        this.searchInfo2.page = this.currentPage2;
        this.searchInfo2.limit = this.pagesize2;
      }
      this.searchInfo2.type = 2;
      this.$http
        .post("index/recharge/myrechargerecord", this.searchInfo2)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.tableInfo2 = res.data.resultData.list;
            this.tableTotal2 = parseInt(res.data.resultData.count);
          }
        });
    },
    // 去续费
    gorenew() {
      this.$router.push("vipPay");
    },
    goxufei(row) {
      this.setorderTime({
        orderTime: row.transaction_time,
      });
      this.setorderId({
        orderId: row.trade_no,
      });
      this.setcodeImg({
        codeImg: row.url,
      });
      this.setorderFee({
        orderFee: row.account,
      });
      this.$router.push("weChatPay");
    },
    // 会员信息
    getVipInfo() {
      console.log(this.userInfo.uid);
      this.$http
        .post("index/recharge/recharge", { id: this.userInfo.uid })
        .then((res) => {
          // if (res.data.resultCode === 1) {
          this.vipInfo = res.data.resultData;
          if (res.data.resultData.u_grade_end != "") {
            this.vipEndTime = res.data.resultData.u_grade_end.slice(0, 10);
          } else {
            this.vipEndTime = "";
          }
          // }
        });
    },
  },
  mounted() {
    this.getTableData1();
    this.getTableData2();
    this.getVipInfo();
  },
};
</script>
<style scoped>
.vipInfoBox {
  height: 120px;
  background: #fbfbfd;
  box-shadow: 1px 1px 3px 0px rgba(176, 176, 176, 0.5);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 100%;
  transition: 0.4s;
}
.vipBoxLeft {
  padding: 10px;
}
.vipFunction {
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  padding: 0px 5px;
  border-radius: 0px 17px 0px 17px;
  color: #fff;
  transition: 0.1s;
  line-height: 20px;
}
.vipInfoBox.first .vipFunction {
  background: linear-gradient(180deg, #00baff 0%, #2573f1 100%);
}
.vipInfoBox.second .vipFunction {
  background: linear-gradient(180deg, #fda944 0%, #f56921 100%);
}
.vipInfoBox.three .vipFunction {
  background: linear-gradient(180deg, #71e2ff 0%, #1babe1 100%);
}
.vipInfoBox.four .vipFunction {
  background: linear-gradient(180deg, #78f98a 0%, #00b666 100%);
}
.vipBoxRight .vrtop {
  padding: 15px 0 0px;
}
.vipBoxRight .vrmiddle {
  text-align: center;
}
.vipBoxRight .vrmiddle .vipNum {
  font-size: 32px;
}
.vipBoxRight .vrmiddle .vipGuoqi {
  font-size: 22px;
  padding: 7px 0;
  display: block;
}
.vipInfoBox.first:hover {
  background: linear-gradient(180deg, #00baff 0%, #2573f1 100%);
}
.vipInfoBox.first:hover .vipFunction {
  background: #fff;
  color: #2573f1;
}
.vipInfoBox.second:hover {
  background: linear-gradient(180deg, #fda944 0%, #f56921 100%);
}
.vipInfoBox.second:hover .vipFunction {
  background: #fff;
  color: #f56921;
}
.vipInfoBox.three:hover {
  background: linear-gradient(180deg, #71e2ff 0%, #1babe1 100%);
}
.vipInfoBox.three:hover .vipFunction {
  background: #fff;
  color: #1babe1;
}
.vipInfoBox.four:hover {
  background: linear-gradient(180deg, #78f98a 0%, #00b666 100%);
}
.vipInfoBox.four:hover .vipFunction {
  background: #fff;
  color: #00b666;
}
.vipInfoBox:hover .xufeiBtn {
  color: #ffdf6f;
}
.vipInfoBox:hover .vrtop,
.vipInfoBox:hover .vrmiddle {
  color: #fff;
}
</style>
